* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

p {
  line-height: 1.8;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #16212d;
}

.header .links {
  margin: 0 10px;
  color: #fff;
  text-decoration: none;
}

.header .links:active{
  color: red;
}

.showcase {
  background: url('./assets/image-4.jpg')
    no-repeat center/cover;
  height: 720px;
}

.showcase .showcase-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 720px;
  text-align: center;
  color: #fff;
}

.showcase .showcase-overlay h1 {
  font-size: 36px;
  padding-bottom: 20px;
}

.showcase .showcase-overlay img{
  height: 120px;
  width: 120px;
  border-radius: 50px;
}

.destinations {
  padding: 50px;
  text-align: center;
}


.destinations h3 {
  font-size: 30px;                                                                                    
  padding: 10px;
  text-align: center;
}

.destinations button{
  font-size: 20px;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 20px;
}

.destinations button:hover{
  cursor: pointer;
  background-color: #f1f1f199;
}

.destinations .grid div {
  background-color: #f1f1f1;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 10px;
}            
.destinations .grid div ul li{
  list-style-type: none;
}
.destinations .grid div:hover{
  background-color: #f1f1f199;
  transform: translateY(6px);
}

/* .destinations button:{} */

.destinations .grid div img {
  border-radius: 10px;
}


.footer {
  background-color: #333;
  padding: 40px;
}

.footer ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer ul li {
  margin: 0 20px;
}

.footer ul li button {
  background-color: transparent;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 5px;
  margin: 10px 0;
  cursor: pointer;
  font-family: inherit;
  transition: background-color 0.4s ease-in-out;
}

.footer ul li button:hover {
  background-color: #2c7f86;
}

/* Login and SignUp */
.login .form-control {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login .form-control input {
  margin: 5px 0;
  padding: 12px;
  border: none;
  border-radius: 10px;
  width: 30vw;
  font-family: inherit;
  font-weight: 700;
  letter-spacing: 2px;
}

.login .form-control select {
  margin: 5px 0;
  padding: 12px;
  border: none;
  border-radius: 10px;
  width: 30vw;
  font-family: inherit;
  font-weight: 700;
  letter-spacing: 2px;
}

.login .form-control button {
  background-color: #2c7f86;
  padding: 8px;
  border: 2px solid #2c7f86;
  color: #fff;
  font-family: inherit;
  border-radius: 10px;
  font-weight: 700;
  width: 300px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 18px;
  margin-top: 5px;
  cursor: pointer;
  transition: background-color 0.4s;
}

.login .form-control .links{
  margin-top: 50px;
  color: red;
}

.login .form-control button:hover {
  background-color: transparent;
}

@media (min-width: 768px) {
  .showcase {
    background-position: top;
  }

  .showcase .showcase-overlay h1 {
    font-size: 64px;
  }

  .showcase .showcase-overlay p {
    font-size: 18px;
  }

  .destinations .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  .destinations .grid div img {
    height: 150px;
    width: 300px;
  }

  .footer ul {
    flex-direction: row;
    justify-content: space-between;
  }

  .login .form-control input {
    width: 30vw;
  }
}

/*Slider */

.slider {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 1000px;
  height: 600px;
  border-radius: 10px;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 32px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}

.logout{
  display: flex;
  font-display: row;
  gap: 20px;
}